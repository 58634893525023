import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  styled,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { BLACK, GRAY_LIGHT, GRAY_LIGHT_RGB_BORDER, GRAY_MAIN, WHITE } from '../../theme/colors';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import settings from '../../utilities/settings';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import { delay } from '../../utilities/delay';
import { dashboard } from '../../hooks/actions/dashboard';
import { useWeb3Context } from '@w3connector/web3provider';
import { validateAddress } from '../../helpers/ethers';
import CheckBoxTermsAndConditions from '../CheckBoxTermsAndConditions';


import { useDepositContract, useTokenContract } from '../../hooks/web3';
import { ethers } from 'ethers';
import { ETokensDepositStatus, IDepositRequestModel, IDepositStatusRequestModel } from '../../models/deposit';
import { depositRequest, depositStatusRequest } from '../../hooks/actions/deposit';
import generateRandomString from '../../helpers/randomString';

const FormCustom = styled('form')(({theme})=>({
    width:'100%',                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

const ResponseBox = styled(Box)(({theme})=>({
    width:'100%',
    marginTop:"30px",                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

interface DepositFormProps {
    setStatus: (status: boolean) => void;
    status: boolean;
};

const DepositForm: React.FC<DepositFormProps> = ({setStatus, status}) => {
    const [response, setResponse] = useState(null);
    const {  
        modalOpen,
        connectors,
        web3Error,
        web3Hooks,
        chainName,
        setModalOpen,
        setWeb3Error,
        handleDisconnect } = useWeb3Context();
    const {t} = useTranslationContext();
    const [tokenBalance, setTokenBalance] = useState<string>("");
    const [tokenBalanceRemoduled, setTokenBalanceRemoduled] = useState<string>("");
    const [maincoinBalance, setMainCoinBalance] = useState<string>("");
    const tokenContract = useTokenContract();
    const depositContract = useDepositContract();

    const [amountAvaiable, setAmountAvaible] = useState<number>(0);

    const [amount, setAmount] = useState('');
    const [errorAmount, setErrorAmount] = useState(false);
    const [errorAmountMessage, setErrorAmountMessage] = useState('')

    const [isSended, setIsSended] = useState(false);
    const [mainErrorMex, setMainErrorMex]=useState<string | null>(null)
    const [mainResponseMex, setMainResponseMex]=useState<string | null>(null)

    const {setWaiterView} = useWaiter();
    const [isChecked, setIsChecked] = useState(false); 
    const [tokenRequest, setTokenRequest]= useState<string>(generateRandomString(20));


    useEffect(()=>{
        setWaiterView(true)
        delay(500).finally(()=>{
            dashboard()
                .then(resp => {
                    setAmountAvaible(resp.balance)
                })
                .catch(error => {
                    // initLoad.current = false
                    console.log(error)
                })
            setWaiterView(false)
        })
    },[])

    useEffect(()=>{
        if(web3Hooks.isActive && web3Hooks.account){
            getTokeBalance(web3Hooks.account)
                .then(resp => {
                    setTokenBalance(resp);
                    const remoduled = (parseFloat(resp) / 101 * 100).toFixed(settings.Token.Decimals)
                    setTokenBalanceRemoduled(remoduled);
                })
                .catch(error => {
                    console.error(error)
                })
            
                getMainCoinBalance(web3Hooks.account, web3Hooks.provider)
                    .then(resp => {
                        setMainCoinBalance(resp);
                    })
                    .catch(error => {
                        console.error(error)
                    })
        }
    },[web3Hooks, amountAvaiable])

    const handleInputAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setErrorAmount(false);
        setErrorAmountMessage('');
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if(value === ''){
            setAmount('');
            setErrorAmount(false);
            return;
        }

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > settings.Token.Decimals) {
                setErrorAmount(false);
                return;
            }
        }

        if(!regex.test(value)){
            setErrorAmountMessage(t("Inserisci un importo valido"))
            setErrorAmount(true)
        }

        if (parseFloat(value) > 0) {
            if(parseFloat(value) > parseFloat(tokenBalanceRemoduled)){
                setAmount(parseFloat(tokenBalanceRemoduled).toFixed(settings.Token.Decimals))
                return;
            }
            setAmount(value);
            setErrorAmount(false);
            
        } 
    };

    const handleBlurAmount = () => {
        if (!errorAmount && amount) {
            const newAmount = parseFloat(amount).toFixed(settings.Token.Decimals)
            setAmount(newAmount);
            setErrorAmount(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSended(true);
        setWaiterView(true);
        setMainErrorMex(null);
        setMainResponseMex(null);

        if(!errorAmount && parseFloat(amount) > 0 && parseFloat(amount) <= parseFloat(tokenBalance) && isChecked && web3Hooks.isActive)
        {
            const newReq:IDepositRequestModel = {
                amount: parseFloat(amount),
                acceptTerms: isChecked,
                clientToken: tokenRequest
            }
            try{
                // richiesta al db
                const deposit = await depositRequest(newReq);

                const spender:string = settings.DepositContract.ContractAddress;
                const tokenParsed = ethers.utils.parseUnits(deposit.amount, 0);
                
                // approve
                const allowance: ethers.BigNumber = await tokenContract.allowance(web3Hooks.account, spender);

                if( allowance.lt(tokenParsed) )
                {
                    try{
                        const approve = await tokenContract.approve(spender, tokenParsed);
                        const approvePromise = approve.wait();
                        const timeoutPromise = new Promise((_, reject) =>
                            setTimeout(() => reject(new Error("Timeout exceeded")), 180000)
                        );
                        await Promise.race([approvePromise, timeoutPromise]);
                        await delay(30000);
                    }catch(error){
                        throw error;
                    }
                }

                try {
                    const transaction = await depositContract.DepositTokens(deposit.refCode, tokenParsed);
                    const transactionPromise = transaction.wait();
                    const timeoutPromise = new Promise<never>((_, reject) =>
                        setTimeout(() => reject(new Error("Timeout exceeded")), 180000)
                    );
                
                    // Attendere la transazione o il timeout
                    await Promise.race([transactionPromise, timeoutPromise]);
                    await delay(30000);

                    dashboard()
                        .then(resp => {
                            setAmountAvaible(resp.balance)
                        });
                    
                    setMainResponseMex(t("Transazione avvenuta con successo. Tx Hash: ") + transaction.hash)
                    setIsChecked(false);
                    setAmount('');
                    setTokenRequest(generateRandomString(20));
                } catch (error) {
                    if (error instanceof Error && error.message === "Timeout exceeded") {
                        setTokenRequest(generateRandomString(20));
                    }
                    throw error;
                }
                
            }catch(e:any){
                // setResponse(null);
                const message = e || e?.message || e?.data || e?.data?.error || e?.data?.error?.message  || t("Errore durante il deposito");
                console.error("DEPOSIT", message);
                setMainErrorMex(message);
                setIsSended(false);
            }
            setWaiterView(false);
            setIsSended(false);
        }
        setStatus(!status); // reload delle ultime transazioni
    };

    //FUNCTIONS
    const getTokeBalance = async (account: string): Promise<string> => {
        if(web3Hooks.isActive){
            try{
                const balance = await tokenContract.balanceOf(account);
                return parseFloat(ethers.utils.formatUnits(balance, settings.Token.Decimals)).toFixed(settings.Token.Decimals);
            }catch(e){
                console.error(e);
                throw e;
            }
        }else{
            throw t("Wallet non connesso")
        }
    }

    const getMainCoinBalance = async(account: string, provider: any): Promise<string> => {
        if(web3Hooks.isActive){
            try{
                const balance = await provider.getBalance(account);
                return parseFloat(ethers.utils.formatUnits(balance, settings.Token.Blockchain.MainCoinDecimals)).toFixed(settings.Token.Blockchain.MainCoinDecimals);
            }catch(e){
                console.error(e);
                throw e;
            }
        }else{
            throw t("Wallet non connesso")
        }
    }

    return (
        <Box sx={{width:'100%'}}>
            <Paper sx={{ width: '100%', display: 'flex', minHeight:'350px', border: 1, borderColor: 'divider', boxShadow:5}}>
                
                <Box sx={{ flexGrow: 1, p: 3, position: 'relative' }}>
                    {response && (
                        <IconButton
                            onClick={() => window.print()}
                            sx={{ position: 'absolute', top: 16, right: 16 }}
                        >
                            <PrintIcon />
                        </IconButton>
                    )}

                    <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardContent 
                            sx={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'100%',
                                padding:'24px 0 0 0',
                                flexDirection:'column'
                            }}
                        >
                            <Typography variant="h4" gutterBottom  color={BLACK} >
                                {t("Deposita ASSO")}
                            </Typography>

                            { !response &&
                                <FormCustom onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{marginTop:'30px'}}>
                                        
                                        {web3Hooks && web3Hooks.isActive ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={0} sx={{fontSize:"0.8rem"}}>
                                                        {t("ASSO disponibili nel tuo wallet: ")} 
                                                        <strong>{tokenBalance} ASSO</strong> {t(" di cui utilizzabili al massimo: ")} <strong>{tokenBalanceRemoduled} ASSO</strong> 
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={0} sx={{fontSize:"0.8rem"}}>
                                                        {settings.Token.Blockchain.MainCoinSymbol} {t("disponibili nel tuo wallet: ")} 
                                                        <strong>{parseFloat(maincoinBalance).toFixed(4)} {settings.Token.Blockchain.MainCoinSymbol}</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={5} sx={{fontSize:"0.8rem"}}>
                                                        {t("ASSO presenti nel tuo account: ")} 
                                                        <strong>{amountAvaiable} ASSO</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} display="grid">
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                        fontSize:"0.8rem"}}>
                                                        🟢 {t("Il tuo wallet è connesso con l'indirizzo: ")}
                                                        <strong>{web3Hooks.account}</strong>
                                                    </Typography>
                                                    
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        onClick={() => {handleDisconnect()}}
                                                        variant="contained"
                                                        sx={{fontSize:"0.7rem", width:'200px', color:WHITE, marginBottom:'50px'}}>
                                                            {t("Disconnetti Wallet")}
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label={t("Inserisci l'importo che vuoi depositare")}
                                                        name="amount"
                                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {(handleInputAmountChange(evt))}}
                                                        onClick={() => {
                                                            setAmount('')
                                                            setErrorAmount(false)
                                                        }}
                                                        onBlur={() => handleBlurAmount()}
                                                        value={amount}
                                                        error={errorAmount}
                                                        // disabled={}
                                                        helperText={
                                                            errorAmount ? errorAmountMessage : t('Importo da depositare')
                                                        }
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline':{
                                                                borderColor: GRAY_LIGHT_RGB_BORDER
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <CheckBoxTermsAndConditions isChecked={isChecked} setIsChecked={setIsChecked}/>

                                                <Grid item xs={12}>
                                                    <Button type="submit" variant="contained" disabled={isSended || amount === '' || errorAmount || !isChecked} 
                                                        sx={{
                                                            color:WHITE, 
                                                            border:'0'
                                                        }}>
                                                        {t("Invia")}
                                                    </Button>
                                                </Grid>
                                            </>
                                            :
                                            <Grid item xs={12}>
                                                <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{fontSize:"0.8rem"}}>
                                                🔴 <strong>{t("Il tuo wallet è disconnesso")}</strong>
                                                </Typography>
                                            </Grid>
                                        }

                                        {!web3Hooks || !web3Hooks.isActive &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                    
                                                        <Grid item xs={12} sx={{
                                                            padding: {
                                                                xs:'0 25px',
                                                                sm:'0 0 0 5px'
                                                            },
                                                            marginTop:{
                                                                xs:'10px',
                                                                sm:'0'
                                                            },
                                                            marginButton:{
                                                                xs:'20px',
                                                                sm:'0px'
                                                            },
                                                            display:'flex',
                                                            justifyContent:'center',
                                                            alignItemsItems:'center'
                                                        }}>
                                                            <Button 
                                                                disabled={isSended}
                                                                variant="contained"
                                                                onClick={() => {
                                                                    (!web3Hooks || !web3Hooks.isActive) &&
                                                                    setModalOpen(true)
                                                                }}
                                                                sx={{
                                                                    height:'60px',  
                                                                    color:WHITE,       
                                                                    padding: {
                                                                        xs: '6x 0px',
                                                                    },
                                                                    margin: {
                                                                        xs: '0',
                                                                        sm: '0'
                                                                    },
                                                                    maxWidth:'200px'
                                                                }}
                                                            >
                                                                {t("Connetti portafogli")}   
                                                            </Button>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        
                                        {
                                            mainErrorMex && <Grid item xs={12} display="grid" marginBottom={2} marginTop={2}>
                                                <Typography fontSize="0.75rem" color="red" 
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                    }}
                                                >
                                                    {mainErrorMex}
                                                </Typography>
                                            </Grid>
                                        }

{
                                            mainResponseMex && <Grid item xs={12} display="grid" marginBottom={2} marginTop={2}>
                                                <Typography fontSize="0.75rem" color="green" 
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3, // Limita il numero di righe del testo
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        maxWidth: '100%', // Impedisce l'uscita dal contenitore
                                                    }}
                                                >
                                                    {mainResponseMex}
                                                </Typography>
                                            </Grid>
                                        }


                                    </Grid>
                                </FormCustom>
                            }

                            { response && <></>
                                // <ResponseBox> {response.refCode}
                                //     {
                                //         <WithdrawResponse response={response} />
                                //     }
                                // </ResponseBox> 
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Box>
    );
};

export default DepositForm;

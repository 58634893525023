import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTranslationContext } from "../../../../hooks/translations/translationsProvider";
import RedeemListTable from "./RedeemTable";

const RedeemManagement: React.FC = () => {
    const {t} = useTranslationContext();
    return (
        <Box >
            <Typography variant="h3" gutterBottom>
                {t("Gestione Riscatto ASSO")}
            </Typography>

            <Divider sx={{ mb: 3, mt:3 }} />

            <Typography variant="h5" gutterBottom>
                {t("Riscatto")}
            </Typography>
            
            <RedeemListTable />


        </Box>
    );
};

export default RedeemManagement;

export interface PrivateUserInformations {
    id: string;
    userId: string;
    nome: string;
    cognome: string;
    born: Date; 
    address: string;
    comuneDiNascita: string;
    statoDiNascita: string;
    city: string;
    cap: number;
    provincia: string;
    nation: string;
    association: string;
    associationId: string;
    codiceFiscale: string;
}

// Interface for CompanyAssociationInformations
export interface CompanyAssociationInformations {
    id: string;
    userId: string;
    nomeAziendaAssociazione: string;
    addressSedeLegale: string;
    citySedeLegale: string;
    capSedeLegale: number; 
    nationSedeLegale: string;
    provinciaSedeLegale: string;
    partitaIvaVat: string;
    codiceFiscaleAziendale: string;
    websiteAziendale: string;
    telefonoAziendale: string;
    mailRichiestePubblicheAziendale: string;
    nome: string;
    cognome: string;
    born: Date; 
    address: string;
    comuneDiNascita: string;
    statoDiNascita: string;
    city: string;
    cap: number; 
    provincia: string;
    nation: string;
    codiceFiscale: string;
}

// Interface for UserInfosModel
export interface UserInfosModel {
    userid: string; 
    isPrivate: boolean;
    conventioned: boolean;
    email: string;
    enabledUser: boolean;
    username: string;
    userType: number;
    refCode: string;
    association: string;
    associationId?: string;

    approvedFromAssociation?: EApprovedFromAssociation;
    privateInfo?: PrivateUserInformations; 
    companyAssociationInfo?: CompanyAssociationInformations; 
    lastDateAssociationAction?: Date; 
    removedFromAssociation?: Date; 
    companyOrAssociationStatus?: number; 
}

// request modify model
export interface PrivateUserInfoRequestModel{
    userid: string; 
    isPrivate: boolean;
    conventioned: boolean;
    email: string;
    enabledUser: boolean;
    username: string;
    userType: number;
    refCode: string;
    association: string;
    associationId?: string;
    lastDateAssociationAction?: Date; 
    removedFromAssociation?: Date; 
    approvedFromAssociation?: EApprovedFromAssociation;
    userInfoId: string;
    nome?: string;
    cognome?: string;
    born?: Date; 
    address?: string;
    comuneDiNascita?: string;
    statoDiNascita?: string;
    city?: string;
    cap?: number;
    provincia?: string;
    nation?: string;
    codiceFiscale?: string;
}

export interface CompanyAssociationInfoRequestModel {
    userid: string;            
    isPrivate: boolean;
    conventioned: boolean;
    email: string;
    enabledUser: boolean;
    username: string;
    userType: number;
    refCode: string;
    association?: string;
    companyOrAssociationStatus: ECompanyOrAssociationStatus;
    userInfoId?: string;        // id del database CompanyOrAssociationInfo
  
    // Amministratore
    nome?: string;               // obbligatorio
    cognome?: string;            // obbligatorio
    born?: Date;                 // obbligatorio (Date corrisponde a DateTime in C#)
    address?: string;            // obbligatorio
    comuneDiNascita?: string;    // obbligatorio
    statoDiNascita?: string;     // obbligatorio
    city?: string;               // obbligatorio
    cap?: number | undefined;                // obbligatorio (int corrisponde a number in TypeScript)
    provincia?: string;          // obbligatorio
    nation?: string;             // obbligatorio
    codiceFiscale?: string;      // obbligatorio
  
    // Info azienda
    nomeAziendaAssociazione?: string;   // obbligatorio
    addressSedeLegale?: string;         // obbligatorio
    citySedeLegale?: string;            // obbligatorio
    capSedeLegale?: number | undefined;             // obbligatorio
    nationSedeLegale?: string;          // obbligatorio
    provinciaSedeLegale?: string;       // obbligatorio
    partitaIvaVat?: string;
    codiceFiscaleAziendale?: string;    // obbligatorio
    websiteAziendale?: string;
    telefonoAziendale?: string;
    mailRichiestePubblicheAziendale?: string;
}

export enum EApprovedFromAssociation {
  FirstRequest = 0,
  ApprovedFromAssociation = 1,
  RemovedFromAssociation = 2,
  //UserWithoutAssociation = 3
}

export enum ECompanyOrAssociationStatus {
  // ACTIVE = 0, // Attiva
  // INACTIVE = 1, // Inattiva
  // PENDING = 2, // In attesa
  // DEACTIVATED = 3, // Disattivata
  FirstRequest = 0,
  ApprovedFromAdmin = 1,
  LockedFromAdmin = 2,
}

export interface UsersListResponseModel
{
  totalRecords:number;
  users:UserInfosModel[];
}

export interface AssociationConfirmUserModel
{
  associationId:string;
  userId:string;
  confirmUser:ConfirmUser;
  userInfosModel :UserInfosModel;
}

export enum ConfirmUser {
  Confirm = 0,
  Reject = 1
}
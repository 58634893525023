import React, { useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslationContext } from '../../../../../../hooks/translations/translationsProvider';
import { WHITE } from '../../../../../../theme/colors';

interface CreditFormProps {
  onSubmit: (amount: number, info: string) => Promise<void>;
}

const TokensForm: React.FC<CreditFormProps> = ({ onSubmit }) => {
  const [amount, setAmount] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslationContext();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const handleConfirm = async () => {
    setIsDialogOpen(false);
    await onSubmit(parseFloat(amount), info);
    setAmount('');
    setInfo('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
            label={t("Token ASSO da Aggiungere/Rimuovere")}
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            margin="normal"
            helperText={t("Enter a positive number to add credits or a negative number to remove credits")}
        />

        <TextField
            label={t("Informazioni relative all'operazione")}
            multiline
            rows={3} 
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder={t("Informazioni relative all'operazione")}
            InputProps={{
                sx: {
                    resize: "vertical", // Permette il ridimensionamento solo in verticale
                    overflow: "auto", // Aggiunge lo scroll se necessario
                },
            }}
        />

      <Button 
        type="submit" 
        variant="contained" 
        color="info" 
        disabled={!amount || isNaN(parseFloat(amount)) || parseFloat(amount) === 0}
        sx={{
          margin:"10px 0 0 0",
          height: '35px',
          color:WHITE
        }}
      >
        {t("Update Credits")}
      </Button>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>{t("Confirm Credit Update")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to update the user's credits by")} {amount}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            {t("Annulla")}
          </Button>
          <Button onClick={handleConfirm} color="error" autoFocus>
            {t("Conferma")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TokensForm;


import React, { useEffect, useRef, useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  styled,
  Grid,
  TextField,
  alpha,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslationContext } from '../../../../hooks/translations/translationsProvider';
import { getEnumKey } from '../../../../helpers/getEnumKey';
import { AdminBuyResponseModel, EBuyStatus, EBuyType, IBuyModifyRequestModel, IConfirmBuyRequestModels } from '../../../../models/buy/Buy';
import Search from '../../../Search';
import CustomWaiter from '../../../Waiter';
import { theme } from '../../../../theme';
import { BLUE_LIGHT_RGB_BORDER, GRAY_LIGHT_RGB_BORDER, WHITE } from '../../../../theme/colors';
import { delay } from '../../../../utilities/delay';
import TableCellEditable from '../TableCellEditable';
import DatePickerCustom from '../../../DatePicker/DatePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import { buyConfirmTransaction, buyModifyRecord, buyRequestList } from '../../../../hooks/actions/admin';
import { useWaiter } from '../../../../hooks/waiter/WaiterProvider';

const TabsCellHeaderCustom = styled(TableCell)(({theme})=>({
  fontWeight:'bold', 
  whiteSpace:'nowrap',
  fontSize:'0.7rem',     
  minWidth:'150px',                                
  [theme.breakpoints.up('xs')]: {
  },
}))

const TabsCellCustom = styled(TableCell)(({theme})=>({
  fontSize:'0.7rem',   
  minWidth:'150px',                                    
  [theme.breakpoints.up('xs')]: {
  },
}))

const BuyListTable: React.FC = () => {
  const [originalData, setOriginalData] = useState<AdminBuyResponseModel[]>([]);
  const [editableData, setEditableData] = useState<AdminBuyResponseModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const {t} = useTranslationContext();
  const minChars = 2;
  const [searchWaiter, setSearchWaiter] = useState<boolean>(false);
  const [typeFilter, setTypeFilter] = useState<number | null>(null);
  const [approvalFilter, setApprovalFilter] = useState<number | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const {setWaiterView} = useWaiter();

  // Simulate fetching data (replace this with your API call)
  const fetchData = useCallback(async () => {
    try {
      const response = await buyRequestList(page, rowsPerPage, searchTerm, approvalFilter, typeFilter);
      setOriginalData(response);
      setEditableData(JSON.parse(JSON.stringify(response)));
      await delay(500);
      setSearchWaiter(false);
    } catch (e) {
      console.error(e);
    }
  }, [page, rowsPerPage, searchTerm, approvalFilter, typeFilter]);

  useEffect(() => {
    fetchData(); // inizializzazione
  },[]);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    setSearchWaiter(true);
    debounceTimeout.current = setTimeout(() => {
      fetchData();
      debounceTimeout.current = null;
    }, 1200);
  }, [fetchData]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInput = (newString: string) => {
    if (newString.length < minChars) {
      setSearchTerm(null);
      return;
    }
    if (newString === searchTerm) return;
    if (newString.length > 20) return;
    setSearchTerm(newString);
  };

  const handleEditableChange = useCallback((
    rowIndex: number,
    field: string,
    value: string,
  ) => {
    setEditableData(prevData => {
      const updatedData = [...prevData];
      const keys = field.split('.');
      let current: any = updatedData[rowIndex];
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      // Only recalculate if amount, fixedAmount, or percentValue changed
      if (field === 'buy.amount' || field === 'buy.fixedAmount' || field === 'buy.percentValue') {
        const item = updatedData[rowIndex];
        const totalAmount = Number(item.buy.amount);
        const fixedAmount = Number(item.buy.fixedAmount);
        const percentValue = Number(item.buy.percentValue);
        const decimals = item.buy.decimals;
        
        const percentAmount = Number((totalAmount * percentValue / 100).toFixed(decimals));
        const netAmount = totalAmount - percentAmount - fixedAmount;
        
        updatedData[rowIndex] = {
          ...item,
          buy: {
            ...item.buy,
            netAmount: netAmount,
            percentAmount: percentAmount
          }
        };
      }

      return updatedData;
    });
  }, []);

  const handleChangeDate = useCallback((
    date: Dayjs | null,
    rowIndex: number,
    field: string // Nome del campo, es. "buy.dataReceved"
  ) => {
    if (date !== null && date.isValid()) {
      const updatedData = [...editableData];
      const keys = field.split('.'); // Dividi il campo in più chiavi
      let current: any = updatedData[rowIndex];

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]]; // Scendi nel percorso dell'oggetto
      }

      current[keys[keys.length - 1]] = date.toDate(); // Imposta il valore della data
      setEditableData(updatedData); // Aggiorna lo stato
    }
  }, []);

  const handleStatusChange = useCallback((rowIndex: number, newStatus: string | number) => {
    const updatedData = [...editableData];

    // Converti `newStatus` a `EBuyStatus`
    updatedData[rowIndex].buy.status = Number(newStatus) as EBuyStatus;

    setEditableData(updatedData); // Aggiorna lo stato globale
  }, []);

  const handleEdit = useCallback((index: number) => {
    if(editRowIndex === null || editRowIndex === undefined)
      setEditRowIndex(index);
  }, []);

  const handleCancel = useCallback((index:number) => {
    const editableDataRestore = [...editableData];
    editableDataRestore[index] = JSON.parse(JSON.stringify(originalData[index]));
    setEditableData(editableDataRestore);
    setEditRowIndex(null);
  }, [editableData, originalData]);

  const handleSave = useCallback(async (index:number) => {
    try{
      setWaiterView(true);
      const newValues = editableData[index];
      const model: IBuyModifyRequestModel = {
        id: newValues.buy.id,
        userName: newValues.userName,
        amount: newValues.buy.amount,
        dataReceved: newValues.buy.dataReceved?? new Date("0001-01-01T00:00:00Z"),
        status: newValues.buy.status,
        transactionNumber: newValues.buy.transactionNumber,
        info: newValues.buy.info,
        rejectedInfo: newValues.buy.rejectedInfo,
        dataRejected: newValues.buy.dataRejected ?? new Date("0001-01-01T00:00:00Z"),
        dataRefounded: newValues.buy.dataRefounded ?? new Date("0001-01-01T00:00:00Z"),
        refoundedInfo: newValues.buy.refoundedInfo,
        refoundedIbanCardAddress: newValues.buy.refoundedIbanCardAddress,
        fixedAmount: newValues.buy.fixedAmount,
        percentValue: newValues.buy.percentValue,
      }
      const editableDataRestore = [...editableData];
      const originalDataRestore = [...originalData];
      const resp = await buyModifyRecord(model);

      editableDataRestore[index] = JSON.parse(JSON.stringify(resp));
      originalDataRestore[index] = JSON.parse(JSON.stringify(resp));

      setOriginalData(originalDataRestore);
      setEditableData(editableDataRestore);
      setEditRowIndex(null);

    }catch(e){
      console.error(e);
    }
    setWaiterView(false);
  }, [editableData, originalData]);

  const handleConfirm = useCallback(async (index:number) => {
    try{
      setWaiterView(true);
      const newValues = editableData[index];
      const model: IConfirmBuyRequestModels = {
        id: newValues.buy.id,
        userName: newValues.userName,
        eBuyType: newValues.buy.buyType
      }
      const editableDataRestore = [...editableData];
      const originalDataRestore = [...originalData];
      const resp = await buyConfirmTransaction(model);

      editableDataRestore[index] = JSON.parse(JSON.stringify(resp));
      originalDataRestore[index] = JSON.parse(JSON.stringify(resp));

      setOriginalData(originalDataRestore);
      setEditableData(editableDataRestore);
      setEditRowIndex(null);

    }catch(e){
      console.error(e);
    }
    setWaiterView(false);
  }, [editableData, originalData]);

  const calculateUpdatedData = useCallback((item: AdminBuyResponseModel) => {
    const totalAmount = Number(item.buy.amount);
    const fixedAmount = Number(item.buy.fixedAmount);
    const percentValue = Number(item.buy.percentValue);
    const decimals = item.buy.decimals;
    
    const percentAmount = Number((totalAmount * percentValue / 100).toFixed(decimals));
    const netAmount = totalAmount - percentAmount - fixedAmount;
    
    return {
      ...item,
      buy: {
        ...item.buy,
        netAmount: netAmount,
        percentAmount: percentAmount
      }
    };
  }, []);

  useEffect(()=>{
    console.log(editableData)
  },[editableData])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', padding: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} display={'flex'}>
            <Search
                handleInput={handleInput}
                search={searchTerm!!}
                setSearch={setSearchTerm}
            />
            
            <div style={{
                paddingTop:'3px',
                paddingLeft:'3px',
                width:searchWaiter && searchTerm ? '50px' : '0px',
                height:'35px'
            }}>
                {searchWaiter && searchTerm && <CustomWaiter size={30} width={40}/>}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label={t("Approvazione")}
              value={approvalFilter === null ? "" : approvalFilter} 
              onChange={(e) => setApprovalFilter(e.target.value === '' ? null : parseInt(e.target.value, 10))}
              variant="outlined"
              sx={{ 
                width: '100%',
                borderRadius:'5px',
                backgroundColor: alpha(theme.palette.primary.light, 0.05),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                },
                '& .MuiInputLabel-root': {
                  marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                  color:'grey'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius:'5px',
                  border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                },
              }}
              InputProps={{
                style: {
                  height: '37px', // Imposta altezza anche alla radice del componente
                  
                },
              }}
            >
              <MenuItem value="">
                <em>{t("Tutti gli status")}</em>
              </MenuItem>
              {Object.keys(EBuyStatus).map((key) => {
                const typedKey = key as keyof typeof EBuyStatus;

                if (isNaN(Number(typedKey))) {
                  const value = EBuyStatus[typedKey];
                  const displayValue = value === 0 ? 0 : value;

                  return (
                    <MenuItem key={typedKey} value={displayValue}>
                      {typedKey === 'Request' ? 'Request' : typedKey}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} mt={4}>
            <TextField
              select
              label={t("Tipo di Pagamento")}
              value={typeFilter === null ? "" : typeFilter} 
              onChange={(e) => setTypeFilter(e.target.value === '' ? null : parseInt(e.target.value, 10))}
              variant="outlined"
              sx={{ 
                width: '100%',
                borderRadius:'5px',
                backgroundColor: alpha(theme.palette.primary.light, 0.05),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                },
                '& .MuiInputLabel-root': {
                  marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                  color:'grey'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius:'5px',
                  border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                },
              }}
              InputProps={{
                style: {
                  height: '37px', // Imposta altezza anche alla radice del componente
                  margin:-1
                },
              }}
            >
              <MenuItem value="">
                <em>{t("Tipo di Pagamento")}</em>
              </MenuItem>
              {Object.keys(EBuyType).map((key) => {
                const typedKey = key as keyof typeof EBuyType;

                if (isNaN(Number(typedKey))) {
                  const value = EBuyType[typedKey];
                  const displayValue = value === 0 ? 0 : value;

                  return (
                    <MenuItem key={typedKey} value={displayValue}>
                      {typedKey === 'Bonifico' ? 'Bonifico' : typedKey}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TabsCellHeaderCustom sx={{
                position: 'sticky',
                left: 0,
                backgroundColor:WHITE,
                minWidth:'90px !important',
              }}
              >{t("EDIT")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Info Utente")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Moneta/Divisa")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Decimali Divisa")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Importo Ricevuto")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Importo Netto")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Commissione fissa in divisa")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Commissione percentuale in divisa")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Valore Commissione in Percentuale")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Codice Univoco Causale")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("IBAN Ricevente")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Titolare IBAN Ricevente")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Informazioni Bancarie Ricevente")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("BIC/SWIFT Ricevente")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Tipo di Pagamento")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Data di Richiesta")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Data Ricezione di Pagamento")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Data Restituzione del Pagamento")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Data Rigetto Transazione")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Info")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Riferimenti bancari sulla transazione (es. CRO)")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Info su eventuale Restituzione")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Info su eventuale Rigetto")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("IBAN, CartaCredito, Wallet, ecc. di Riaccredito")}</TabsCellHeaderCustom>
              <TabsCellHeaderCustom>{t("Status")}</TabsCellHeaderCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                const rowStatus = originalData[index].buy.status;
                return (
                  <TableRow key={index} 
                    sx={{
                      backgroundColor: rowStatus === EBuyStatus.Rejected ? 'rgb(255,0,0,0.15)'
                      : rowStatus === EBuyStatus.Confirmed ? 'rgb(0,255,0,0.15)'
                      : undefined
                    }}
                  >
                    <TabsCellCustom sx={{
                      position: 'sticky',
                      borderRight:'1px solid ' + GRAY_LIGHT_RGB_BORDER,
                      left: 0,
                      backgroundColor: WHITE,
                      zIndex:1,
                      minWidth:'90px !important',
                    }}
                    >
                      {editRowIndex === index ? (
                        <>
                          <IconButton onClick={() => {handleSave(index)}}>
                            <SaveIcon sx={{color:'green'}}/>
                          </IconButton>
                          <IconButton onClick={() => handleCancel(index)}>
                            <CancelIcon sx={{color:'red'}} />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton onClick={() => handleEdit(index)}>
                            <EditIcon sx={{color:'blue'}}/>
                          </IconButton>
                        
                          {rowStatus === EBuyStatus.Request && 
                            <IconButton onClick={() => handleConfirm(index)}>
                              <CheckCircleIcon sx={{color:'green'}}/>
                            </IconButton>
                          }
                        </>
                      )}

                    </TabsCellCustom>
                    <TabsCellCustom>{item.userName}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.divisa}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.decimals}</TabsCellCustom>

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.amount}
                      onChange={(value) => handleEditableChange(index, "buy.amount", value)}
                      regex={new RegExp(`^\\d*(\\.\\d{0,${item.buy.decimals}})?$`)}
                      errorText="Puoi inserire solo numeri"
                      otherText={item.buy.divisa}
                      parsedDecimals={item.buy.decimals}
                      disabled={rowStatus === EBuyStatus.Request ? false : true}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE SOLO SE PENDING*/}

                    <TabsCellCustom>{item.buy.netAmount.toFixed(item.buy.decimals)} {item.buy.divisa}</TabsCellCustom>

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.fixedAmount}
                      onChange={(value) => handleEditableChange(index, "buy.fixedAmount", value)}
                      regex={new RegExp(`^\\d*(\\.\\d{0,${item.buy.decimals}})?$`)}
                      errorText="Puoi inserire solo numeri"
                      otherText={item.buy.divisa}
                      parsedDecimals={item.buy.decimals}
                      disabled={rowStatus === EBuyStatus.Request ? false : true}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TabsCellCustom>{item.buy.percentAmount.toFixed(item.buy.decimals)} {item.buy.divisa}</TabsCellCustom> 

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.percentValue}
                      onChange={(value) => handleEditableChange(index, "buy.percentValue", value)}
                      regex={new RegExp(`^\\d*(\\.\\d{0,${2}})?$`)}
                      errorText="Puoi inserire solo numeri"
                      otherText={"%"}
                      parsedDecimals={2}
                      disabled={rowStatus === EBuyStatus.Request ? false : true}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                    
                    <TabsCellCustom>{item.buy.refCode}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.ibanReceiver}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.ibanOwner}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.bankInfosReceiver}</TabsCellCustom>
                    <TabsCellCustom>{item.buy.bicswiftReceiver}</TabsCellCustom>
                    <TabsCellCustom>{t(getEnumKey(item.buy.buyType, EBuyType))}</TabsCellCustom>
                    
                    <TabsCellCustom sx={{whiteSpace:'nowrap'}}>
                      {dayjs(item.buy.dataRequest).isValid()
                      ? dayjs(item.buy.dataRequest).format('DD/MM/YYYY HH:mm') // Formatta la data nel formato desiderato
                      : '-'}
                    </TabsCellCustom>

                    <TabsCellCustom>
                    {editRowIndex === index ? (
                      <DatePickerCustom
                        label={""}
                        selectedDate={editableData[index].buy.dataReceved ? dayjs(editableData[index].buy.dataReceved) : undefined}
                        handleChangeDate={(date) => handleChangeDate(date, index, "buy.dataReceved")}
                        error={false}
                        errorMessage={""} 
                        disable={false}
                        stackProps={{
                          sx:{
                          }
                        }}
                        dataPickerProps={{
                          slotProps:{
                            textField:{
                              sx:{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  height: '35px', // Altezza dell'outline
                                },
                              },
                              inputProps:{
                                style: {
                                  fontSize: '0.7rem', // Dimensione del testo direttamente nell'input
                                  padding:'8px 14px'
                                },
                              }
                            }
                          }
                        }}
                      />
                    ):(
                      item.buy.dataReceved && dayjs(item.buy.dataReceved).isValid() &&
                      new Date(item.buy.dataReceved).getFullYear() !== 1
                        ? dayjs(item.buy.dataReceved).format('DD/MM/YYYY HH:mm') // Formatta la data
                        : '-'
                    )}
                    </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                    
                    <TabsCellCustom>
                      {editRowIndex === index && rowStatus !== EBuyStatus.Confirmed  ? (
                        <DatePickerCustom
                          label={""}
                          selectedDate={editableData[index].buy.dataRefounded ? dayjs(editableData[index].buy.dataRefounded) : undefined}
                          handleChangeDate={(date) => handleChangeDate(date, index, "buy.dataRefounded")}
                          error={false}
                          errorMessage={""}
                          disable={false}
                          stackProps={{
                            sx:{
                            }
                          }}
                          dataPickerProps={{
                            slotProps:{
                              textField:{
                                sx:{
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    height: '35px', // Altezza dell'outline
                                  },
                                },
                                inputProps:{
                                  style: {
                                    fontSize: '0.7rem', // Dimensione del testo direttamente nell'input
                                    padding:'8px 14px'
                                  },
                                }
                              }
                            }
                          }}
                        />
                      ):(
                        item.buy.dataRefounded && dayjs(item.buy.dataRefounded).isValid() &&
                        new Date(item.buy.dataRefounded).getFullYear() !== 1
                          ? dayjs(item.buy.dataRefounded).format('DD/MM/YYYY HH:mm') // Formatta la data
                          : '-'
                      )}
                    </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TabsCellCustom>
                      {editRowIndex === index && rowStatus !== EBuyStatus.Confirmed ? (
                        <DatePickerCustom
                          label={""}
                          selectedDate={editableData[index].buy.dataRejected ? dayjs(editableData[index].buy.dataRejected) : undefined}
                          handleChangeDate={(date) => handleChangeDate(date, index, "buy.dataRejected")}
                          error={false}
                          errorMessage={""}
                          disable={false}
                          stackProps={{
                            sx:{
                            }
                          }}
                          dataPickerProps={{
                            slotProps:{
                              textField:{
                                sx:{
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    height: '35px', // Altezza dell'outline
                                  },
                                },
                                inputProps:{
                                  style: {
                                    fontSize: '0.7rem', // Dimensione del testo direttamente nell'input
                                    padding:'8px 14px'
                                  },
                                }
                              }
                            }
                          }}
                        />
                      ):(
                        item.buy.dataRejected && dayjs(item.buy.dataRejected).isValid() &&
                        new Date(item.buy.dataRejected).getFullYear() !== 1
                          ? dayjs(item.buy.dataRejected).format('DD/MM/YYYY HH:mm') // Formatta la data
                          : '-'
                      )}
                    </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.info}
                      onChange={(value) => handleEditableChange(index, "buy.info", value)}
                      regex={undefined}
                      errorText=""
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.transactionNumber}
                      onChange={(value) => handleEditableChange(index, "buy.transactionNumber", value)}
                      regex={undefined}
                      errorText=""
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.refoundedInfo}
                      onChange={(value) => handleEditableChange(index, "buy.refoundedInfo", value)}
                      regex={undefined}
                      errorText=""
                      disabled={rowStatus === EBuyStatus.Confirmed ? true : false}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                    
                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.rejectedInfo}
                      onChange={(value) => handleEditableChange(index, "buy.rejectedInfo", value)}
                      regex={undefined}
                      errorText=""
                      disabled={rowStatus === EBuyStatus.Confirmed ? true : false}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TableCellEditable
                      editRowIndex={editRowIndex}
                      index={index}
                      value={editableData[index].buy.refoundedIbanCardAddress}
                      onChange={(value) => handleEditableChange(index, "buy.refoundedIbanCardAddress", value)}
                      regex={undefined}
                      errorText=""
                      disabled={rowStatus === EBuyStatus.Confirmed ? true : false}
                    /> {/** MODIFICABILE DALL'AMMINISTRATORE */}

                    <TabsCellCustom>
                      {editRowIndex === index && rowStatus !== EBuyStatus.Confirmed ? 
                        (
                          <TextField
                            select
                            value={item.buy.status}
                            onChange={(e) => handleStatusChange(index, e.target.value)}
                            variant="outlined"
                            sx={{ 
                              width: '100%',
                              borderRadius:'5px',
                              backgroundColor: alpha(theme.palette.primary.light, 0.05),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.light, 0.1),
                              },
                              '& .MuiInputLabel-root': {
                                marginTop: '-12px', // Aggiungi il margine superiore negativo per la label
                                color:'grey'
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius:'5px',
                                border:'1px solid '+BLUE_LIGHT_RGB_BORDER
                              },
                            }}
                            InputProps={{
                              style: {
                                height: '37px', // Imposta altezza anche alla radice del componente
                                margin:-1,
                                fontSize:'0.7rem'
                              },
                            }}
                          >
                            {Object.entries(EBuyStatus)
                              .filter(([key, value]) => typeof value === 'number') // Considera solo i valori numerici dell'enum
                              .filter(([key, value]) => {
                                if (rowStatus === EBuyStatus.Request || rowStatus === EBuyStatus.Rejected || rowStatus === EBuyStatus.Pending ) {
                                  return value !== EBuyStatus.Confirmed && value !== EBuyStatus.Pending;
                                }
                                return true; // Mostra tutti gli altri stati in altri casi
                              })
                              .map(([key, value]) => (
                                <MenuItem key={value} value={Number(value)}>
                                  {t(getEnumKey(value as EBuyStatus, EBuyStatus))}
                                </MenuItem>
                              ))}
                          </TextField>
                        ):(
                          t(getEnumKey(item.buy.status, EBuyStatus))
                        )
                      }
                    </TabsCellCustom> {/** MODIFICABILE DALL'AMMINISTRATORE */}
                  </TableRow>
                )}
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={originalData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default BuyListTable;


import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTranslationContext } from "../../../hooks/translations/translationsProvider";
import { UserInfosModel } from "../../../models/user/UserInfosModel";
import AssociationPrivateUsersTable from "./AssociationPrivateUserTable";
import AdminPrivateUser from "../Admin/Users/AdminPrivateUser";
import AssociationPrivateUser from "./AssociationPrivateUser";



const AssociationUserManagement: React.FC = () => {
    const {t} = useTranslationContext();
    const [selectedAssociationPrivateUser, setSelectedAssociationPrivateUser] = useState<UserInfosModel | null>(null);
    return (
        <Box >
            {!selectedAssociationPrivateUser ? 
                (
                    <>
                        <Typography variant="h3" gutterBottom>
                            {t("Gestione Utenti")}
                        </Typography>

                        <Divider sx={{ mb: 3, mt:3 }} />

                        <AssociationPrivateUsersTable onUserSelect={setSelectedAssociationPrivateUser}/>

                    </>
                ):(
                    <AssociationPrivateUser
                        user={selectedAssociationPrivateUser}
                        onBack={() => setSelectedAssociationPrivateUser(null)}
                    />
                )
            }
        </Box>
    );
};

export default AssociationUserManagement;

import { Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import UserTokensTable from './UserTokensTable';
import { getUserTokensBalances, postUserTokens } from '../../../../../../hooks/actions/admin';
import { useTranslationContext } from '../../../../../../hooks/translations/translationsProvider';
import { AdminUserTokensBalances } from '../../../../../../models/admin';
import settings from '../../../../../../utilities/settings';
import TokensForm from './TokensForm';
import AccordionCustom from '../../../../../AccordionCustom/AccordionCustom';

interface UserTokensProps {
  userId: string;
}

const UserTokens: React.FC<UserTokensProps> = ({userId}) => {
  const [mainError, setMainError] = useState<string | null>(null);
  const [balances, setBalances] = useState<AdminUserTokensBalances | null>(null)
  const [reloadKey, setReloadKey] = useState(0);
  const {t} = useTranslationContext();

  const fetchBalances = useCallback(() => {
    setMainError(null);
    getUserTokensBalances(userId).then(resp => {
      setBalances(resp);
    }).catch(error => {
      const errorMex =  error?.response?.data?.message ||
                        error?.response?.data ||
                        error?.response ||
                        error?.message ||
                        error ||
                        t?.("Error while requesting data")
      console.error(errorMex)
      setMainError(errorMex);
    });
  }, [userId]);

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances]);

  const handleCreditUpdate = async (amount: number, info: string) => {
    try {
      await postUserTokens(userId, amount, info);
      fetchBalances(); // Refresh balances after update
      setReloadKey(prevKey => prevKey + 1);
    } catch (error:any) {
      const errorMex =  error?.response?.data?.message ||
                        error?.response?.data ||
                        error?.response ||
                        error?.message ||
                        error ||
                        t?.("Error while updating credits")
      console.error(errorMex)
      setMainError(errorMex);
    }
  };

  return (
    <div>
      <Box textAlign='left' margin="0 0 25px 0">
        <Typography fontSize={15}>{t("Balance Disponibile: ")}{balances?.tokensBalance.toFixed(settings.Token.Decimals)}</Typography>
        <Typography fontSize={15}>{t("Balance Bloccato: ")}{balances?.tokensLockedBalance.toFixed(settings.Token.Decimals)}</Typography>
      </Box>

      <AccordionCustom summayTxt={t('Tokens ASSO - Storivo Utente')}>
        <UserTokensTable userId={userId} reloadData={reloadKey}/>
      </AccordionCustom>
      
      <Divider sx={{margin:"30px 0 0 0"}} />

      <TokensForm onSubmit={handleCreditUpdate} />

      {mainError && 
        <>
          <Typography
            variant="body2" 
            color="error"   
            sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
            }}
          >
            {mainError}
          </Typography>
        </>
      }
    </div>
  );
};

export default UserTokens;
